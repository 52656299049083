'use strict';

//toggle icon main-menu
const menuIcon = document.querySelector('#menu-icon');
const navbar = document.querySelector('.main-menu');
const header = document.querySelector('.header');

menuIcon.onclick = () => {
    menuIcon.classList.toggle('bx-x');
    navbar.classList.toggle('active');
}

const sections = document.querySelectorAll(".section"),
    navLinks = document.querySelectorAll('.main-menu__link');

window.addEventListener('scroll',()=>{
    let current = '';
    sections.forEach(section => {
        let sectionTop = section.offsetTop;
        let sectionHeight = section.clientHeight;
        if(scrollY >= sectionTop - sectionHeight / 3){
            current = section.getAttribute("id");
        }
        navLinks.forEach(li =>{
            li.classList.remove('active');
            let linkValue = li.getAttribute("href");
            if(linkValue === '#'+current){
                li.classList.add('active');
            }
        });
    });


    header.classList.toggle('sticky', window.scrollY > 100);
    menuIcon.classList.remove('bx-x');
    navbar.classList.remove('active');
});


// OLD VERSION:
// //toggle icon main-menu
// const menuIcon = document.querySelector('#menu-icon');
// const navbar = document.querySelector('.main-menu');
// const header = document.querySelector('.header');
//
// menuIcon.onclick = () => {
//     menuIcon.classList.toggle('bx-x');
//     navbar.classList.toggle('active');
// }
//
// //scroll
// let sections = document.querySelectorAll('.section');
// let navLinks = document.querySelectorAll('.main-menu__link');
//
//
// window.onscroll = () => {
//     sections.forEach(section => {
//         let top = window.scrollY;
//         let offset = section.offsetTop - 100;
//         let height = section.offsetHeight;
//         // let sectionId = section.getAttribute('id');
//
//         if(top >= offset && (top < offset + height)) {
//             navLinks.forEach(navLink => {
//                    navLink.classList.remove('active');
//                    // console.log(section.id);
//                   // document.querySelector('.main-menu__link[href*=' + section.id +']').classList.add('active');
//             });
//             section.classList.add('show-animate');
//         }
//         else {
//             section.classList.remove('show-animate');
//         }
//     })
//
//     header.classList.toggle('sticky', window.scrollY > 100);
//     menuIcon.classList.remove('bx-x');
//     navbar.classList.remove('active');
//
// }